import Footer from '@/components/Footer/Footer';
import { ReactNode, useEffect } from 'react';
import Head from 'next/head';
import Script from 'next/script';
import ThisMachineNav from '@/components/ThisMachineNav/ThisMachineNav';
import ThisMachineFooter from '@/components/ThisMachineFooter/ThisMachineFooter';
import { useAppContext } from 'lib/context';
import { useRouter } from 'next/router';
import parse from 'html-react-parser';
import { Manrope } from 'next/font/google';
import localFont from 'next/font/local';


const manrope = Manrope({
  subsets: ['latin'],
  weight: ['400', '500', '600', '700', '800'],
});
const iconsFont = localFont({
  src: [
    {
      path: '../../public/fonts/icons.woff',
    },
  ],
});

export default function ThisMachineLayout({
  menus,
  children,
  pageBlocks,

}: {
  menus: { main: any; footer: any };
  pageBlocks?: any;

  children?: ReactNode;
}) {
  const router = useRouter()
  const currentPage = router.asPath.substring(1);
  const { pageType, setPageType } = useAppContext();


  useEffect(() => {
    if (pageBlocks?.__typename && pageBlocks.databaseId) {
      setPageType(pageBlocks.__typename)
      document.body.className = `${`${pageBlocks.__typename.toLowerCase()}-${pageBlocks.databaseId}`}`;
    } else {
      setPageType(currentPage)
      document.body.className = `${currentPage}`;
    }
  }, [pageBlocks?.__typename, currentPage])

  const fullHead = parse(pageBlocks?.seo?.fullHead.replaceAll("Tihomir Todorov", pageBlocks?.publishers?.nodes[0]?.name) || '');


  return (
    <div className='this-machine-theme'>
      <Head>
        <link
          rel="shortcut icon"
          href="/tm-favicon.ico"
        />
        {fullHead}
        {pageBlocks?.seo?.metaRobotsNoindex && pageBlocks?.seo?.metaRobotsNofollow && <meta name="robots" content={`${pageBlocks?.seo?.metaRobotsNoindex}, ${pageBlocks?.seo?.metaRobotsNofollow}`} />}
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta httpEquiv="ScreenOrientation" content="autoRotate:disabled" />
      </Head>
      {/* <Script
        id="aos-init"
        dangerouslySetInnerHTML={{ __html: `AOS.init()` }}
      /> */}
      <div className={manrope.className}>
        <style jsx global>{`
                :root {
                  --root-font: ${manrope.style.fontFamily};
                  --icons-font: ${iconsFont.style.fontFamily};
                }
              `}</style>

        <ThisMachineNav />
        {children}
        <ThisMachineFooter />
      </div>
    </div>
  );
}
